var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-container"},[_c('Vueform',{attrs:{"prepare":_vm.prepareData,"endpoint":false,"sync":"","size":"sm","display-errors":false,"add-class":"vf-create-accounts"},on:{"submit":_vm.onSubmit},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}},[_c('RadiogroupElement',{attrs:{"name":"entityType","default":"company","items":[
            { label: 'Empresa', value: 'company' },
            { label: 'Particular', value: 'person' }
        ],"view":"tabs"}}),_c('GroupElement',{attrs:{"name":"companyContainer"}},[_c('TextElement',{attrs:{"name":"company","placeholder":"Razón Social","columns":{
              container: 6,
          },"rules":[
            'required',
          ],"conditions":[
            ['entityType', '==', 'company']
          ],"field-name":"Empresa"}}),_c('TextElement',{attrs:{"name":"cif","placeholder":"CIF","columns":{
              container: 6,
          },"rules":[
            'required',
          ],"conditions":[
            ['entityType', '==', 'company']
          ],"field-name":"CIF"}})],1),_c('TextElement',{attrs:{"name":"nif","placeholder":"NIF","rules":[
          'required',
        ],"conditions":[
          ['entityType', '==', 'person']
        ],"field-name":"NIF"}}),_c('GroupElement',{attrs:{"name":"personContainer","description":"Persona de contacto"}},[_c('TextElement',{attrs:{"name":"firstName","placeholder":"Nombre","columns":{
              container: 6,
              label: 12,
              wrapper: 12,
          },"field-name":"Nombre","rules":[
            'required',
            'max:255',
          ]}}),_c('TextElement',{attrs:{"name":"lastName","placeholder":"Apellido","columns":{
            container: 6,
            label: 12,
            wrapper: 12,
          },"field-name":"Apellido","rules":[
            'required',
            'max:255',
          ]}})],1),_c('GroupElement',{attrs:{"name":"contactContainer","description":"Recibirás las actualizaciones por correo o sms"}},[_c('TextElement',{attrs:{"name":"phone","input-type":"tel","placeholder":"Teléfono","columns":{
              container: 6,
          },"rules":[
          'required',
        ],"field-name":"Phone"}}),_c('TextElement',{attrs:{"name":"email","input-type":"email","columns":{
              container: 6,
          },"rules":[
          'required',
          'max:255',
          'email',
        ],"placeholder":"Email","field-name":"Email"}})],1),_c('TextElement',{attrs:{"name":"address","placeholder":"Dirección","field-name":"Dirección","rules":[
            'required',
            'max:255',
          ]}}),_c('SelectElement',{attrs:{"name":"state","placeholder":"Provincia","input-type":"search","rules":"required","value-prop":"code","items":_vm.states,"search":true,"resolve-on-load":true,"can-clear":false,"columns":6}}),_c('TextElement',{attrs:{"name":"postalCode","placeholder":"Codigo Postal","rules":"required","columns":6}}),_c('CheckboxElement',{staticClass:"font-small",attrs:{"name":"acceptEmail","text":"Quiero recibir correos de actualizaciones de estado de las reclamaciones"}}),_c('StaticElement',{attrs:{"name":"divider_1","tag":"hr"}}),_c('ButtonElement',{attrs:{"name":"save","submits":true,"button-label":_vm.btnText || 'Guardar',"full":true,"size":"sm"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }