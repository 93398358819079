<template>
      <div class="form-container">
        <Vueform
            :prepare="prepareData"
            :endpoint="false"
            sync
            @submit="onSubmit"
            v-model="data"
            size="sm"
            :display-errors="false"
            add-class="vf-create-accounts"
        >

          <RadiogroupElement
              name="entityType"
              default="company"
              :items="[
                  { label: 'Empresa', value: 'company' },
                  { label: 'Particular', value: 'person' }
              ]"
              view="tabs"
          />

          <GroupElement name="companyContainer">
            <TextElement
                name="company"
                placeholder="Razón Social"
                :columns="{
                    container: 6,
                }"
                :rules="[
                  'required',
                ]"
                :conditions="[
                  ['entityType', '==', 'company']
                ]"

                field-name="Empresa"
            />
            <TextElement
                name="cif"
                placeholder="CIF"
                :columns="{
                    container: 6,
                }"
                :rules="[
                  'required',
                ]"
                :conditions="[
                  ['entityType', '==', 'company']
                ]"
                field-name="CIF"
            />

          </GroupElement>


          <TextElement
              name="nif"
              placeholder="NIF"
              :rules="[
                'required',
              ]"
              :conditions="[
                ['entityType', '==', 'person']
              ]"
              field-name="NIF"
          />


          <GroupElement
              name="personContainer"
              description="Persona de contacto"
          >
            <TextElement
                name="firstName"
                placeholder="Nombre"
                :columns="{
                    container: 6,
                    label: 12,
                    wrapper: 12,
                }"
                field-name="Nombre"
                :rules="[
                  'required',
                  'max:255',
                ]"
            />
            <TextElement
                name="lastName"
                placeholder="Apellido"
                :columns="{
                  container: 6,
                  label: 12,
                  wrapper: 12,
                }"
                field-name="Apellido"
                :rules="[
                  'required',
                  'max:255',
                ]"
            />

          </GroupElement>

          <GroupElement
              name="contactContainer"
              description="Recibirás las actualizaciones por correo o sms"

          >
            <TextElement
                name="phone"
                input-type="tel"
                placeholder="Teléfono"
                :columns="{
                    container: 6,
                }"
                :rules="[
                'required',
              ]"
                field-name="Phone"
            />
            <TextElement
                name="email"
                input-type="email"
                :columns="{
                    container: 6,
                }"
                :rules="[
                'required',
                'max:255',
                'email',
              ]"
                placeholder="Email"
                field-name="Email"
            />

          </GroupElement>

          <TextElement
              name="address"
              placeholder="Dirección"
              field-name="Dirección"
              :rules="[
                  'required',
                  'max:255',
                ]"
          />
          <SelectElement
              name="state"
              placeholder="Provincia"
              input-type="search"
              rules="required"
              value-prop="code"
              :items="states"
              :search="true"
              :resolve-on-load="true"
              :can-clear="false"
              :columns="6"
          />
          <TextElement
              name="postalCode"
              placeholder="Codigo Postal"
              rules="required"
              :columns="6"
          />

          <CheckboxElement
              class="font-small"
              name="acceptEmail"
              text="Quiero recibir correos de actualizaciones de estado de las reclamaciones"
          />
          <StaticElement
              name="divider_1"
              tag="hr"
          />
          <ButtonElement
              name="save"
              :submits="true"
              :button-label="btnText || 'Guardar'"
              :full="true"
              size="sm"
          />
        </Vueform>

      </div>
</template>

<script>

import ApiService from "@/services/api.service";

export default {
  props: ['btnText','preset'],
  name: 'profile',
  components: {},
  data: () => ({
    states: [],
    data: {},
    _id: null,
  }),

  watch: {
    preset: async function (val) {
      const { _id, ...body } = val
      this._id = _id
      this.data = body
    },
  },

  methods: {

    prepareData() {},

    onSubmit() {
      this.$emit('onSubmit', { ...this.data, _id: this._id })
    }
  },
  async mounted() {
    this.states = await ApiService.get(`static/json/provincias.json`)
  }
}
</script>
<style>

</style>


