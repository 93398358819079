<template>
  <div class=" d-flex justify-content-center parent-container animate__animated animate__fadeIn">

    <div class="d-flex flex-column">
      <div class="intro-container my-container-light p-3">

        <h5 class="claim-title" tabindex="0">Añade información de tu empresa</h5>
        <p class="claim-text ">
          Para iniciar una reclamación es necesario introducir la información de tu negocio,
          como el CIF/NIF información de contacto etc... Es importante que introduzcas la mayor información posible y sin errores.
        </p>
        <h5 class="claim-subtitle" tabindex="0">Importar Perfil</h5>
        <p class="claim-text ">
          Si ya has rellenado esta información previamente, puedes importarla aquí.
        </p>

        <Dropdown class="full-width" v-model="selectedProfile" :options="profiles" optionLabel="_id"
                  :filter="false" placeholder="Selecciona Datos" :showClear="true">

          <template #value="slotProps">

              <div class="d-flex profile-pick-text" v-if="slotProps.value">
                <template v-if="slotProps.value.entityType === 'person'">
                  <b-icon class="mt-1 mr-1" icon="person"></b-icon>
                  <b><span class="mr-1">{{slotProps.value.firstName}} {{slotProps.value.lastName}}</span></b>
                </template>
                <template v-else>
                  <b-icon  class="mt-1 mr-1" icon="building"></b-icon>
                  <b><span class="mr-1">{{slotProps.value.company}}</span></b>
                </template>
              </div>

          </template>


          <template #option="slotProps">

            <div class="d-flex flex-column my-dropdown-items p-1" v-if="slotProps.option">
              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <template v-if="slotProps.option.entityType === 'person'">
                    <b-icon class="mt-1 mr-1" icon="person"></b-icon>
                    <b><span class="mr-1">{{slotProps.option.firstName}} {{slotProps.option.lastName}}</span></b>
                  </template>
                  <template v-else>
                    <b-icon  class="mt-1 mr-1" icon="building"></b-icon>
                    <b><span class="mr-1">{{slotProps.option.company}}</span></b>
                  </template>
                </div>
                <div>
                  <span v-if="slotProps.option.entityType === 'person'"><b>NIF:</b> {{slotProps.option.nif}}</span>
                  <span v-else><b>CIF:</b> {{slotProps.option.cif}}</span>
                </div>
              </div>
              <div>
                <span class="mr-1"><b>Email:</b> {{slotProps.option.email}}</span>
                <span><b>Tel.:</b> {{slotProps.option.phone}}</span>
              </div>
              <div>
                <span class="mr-1">{{slotProps.option.address}}</span>
                <span> {{slotProps.option.postalCode}}</span>
                <span> {{resolveState(slotProps.option.state)}}</span>

              </div>

            </div>
          </template>


        </Dropdown>

      </div>
      <div class="pl-3">
        <h5 class="claim-subtitle" tabindex="0">O bien crea nuevo perfil</h5>
      </div>

      <edit-profile
          :preset="storedProfile"
          @onSubmit="onSubmit"
          btn-text="Confirmar">
      </edit-profile>

    </div>


  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ApiService from "@/services/api.service";
import Dropdown from 'primevue/dropdown';
import EditProfile from '@/components/profile';
import {SAVE_PROFILE} from "@/store/actions.type";

export default {
  name: 'claim-profile',
  components: {
    Dropdown,
    EditProfile
  },
  data: () => ({
    selectedProfile: null,
    storedProfile: null,
    profiles: [],

  }),
  watch: {
    selectedProfile: async function (val) {
      this.storedProfile = val
    },
  },

  computed: {
    ...mapGetters(['claim'])
  },
  methods: {
    async onSubmit(obj) {
      await this.$store.dispatch(SAVE_PROFILE, obj)
    },

    resolveState(code) {
    }
  },
  
  async mounted() {
    //get profile from store
    if(this.claim.claim.profile) {
      this.storedProfile = await ApiService.get(`api/profile/${this.claim.claim.profile._id}`)
    }
    this.profiles = await ApiService.get('api/profile')

  }
}
</script>

<style>

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #385048;
  background: none;
}

.profile-pick-text {
  color: cadetblue;
  font-size: 13px
}


</style>


